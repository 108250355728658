<script setup lang="ts">
interface Props {
        preventDefault?: boolean;
        stopPropagation?: boolean;
        disabled?: boolean;
        loading?: boolean;
        className?: string;
        extraClassName?: string;
        type?: 'button' | 'submit' | 'reset';
    }
    import { computed, ref } from 'vue'
    const { $theme } = useNuxtApp();
    
    const props = defineProps({
  preventDefault: { type: Boolean, default: false },
  stopPropagation: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  className: null,
  extraClassName: null,
  type: { default: 'button' }
});
    const emit = defineEmits(['clicked']);
    const buttonClassName = computed(() => {
        return `${props.className ?? $theme('button.style.default')} ${props.extraClassName ?? ''}`;
    });
    const submitted = ref(false);
    async function loadUntil(promise: Promise<any>, minimumDuration: number = 0): Promise<any> {
        load();
        let response = await Promise.all([promise, wait(minimumDuration)]).then((values) => values[0]);
        stop();
        return response;
    }
    function load() {
        submitted.value = true;
    }
    function stop() {
        submitted.value = false;
    }
    function clicked(event: Event) {
        if (props.disabled) {
            return;
        }
        const payload: LoadingButtonClickedEventPayload = {
            event,
            loadUntil,
            load,
            stop,
        };
        emit('clicked', payload);
        if (props.stopPropagation && event) {
            event.stopPropagation();
        }
        if (props.preventDefault && event) {
            event.preventDefault();
        }
    }
    const isLoading = computed(() => {
        return props.loading || submitted.value;
    });
    const isDisabled = computed(() => {
        return props.disabled || isLoading.value;
    });
    defineExpose({
        loadUntil,
        load,
        stop,
    });
</script>

<template>
    <button
        :class="buttonClassName"
        @click="clicked"
        :disabled="isDisabled"
        :type="type"
    >
        <Loader v-if="isLoading" />
        <slot></slot>
    </button>
</template>
